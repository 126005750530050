/*
Replace
screen with your resource Name :) Take care about different writings!
/!\ Screen screen Screens screens /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const screenSlice = createSlice({
  name: "screens",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.show = action.payload;
      state.showId = action.payload.id;
      state.dictionary[action.payload.id] = action.payload;
      state.createError = null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listScreens,
  show: showScreen,
  create: createScreen,
  update: updateScreen,
} = screenSlice.actions;

export const actions = screenSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const screens = (state) =>
  state.screens.idsList.map((id) => state.screens.dictionary[id]);
export const screen = (state) =>
  state.screens.showId && state.screens.dictionary[state.screens.showId];
export const screenAttributes = (state) => screen(state)?.attributes;
export const screenRelationships = (state) => screen(state)?.relationships;
export const screenId = (state) => state.screens.showId;

export const getScreen = createSelector(
  (state) => state.screens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getScreenAttributes = createSelector(
  (state) => state.screens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getScreenRelationships = createSelector(
  (state) => state.screens.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getScreens = createSelector(
  (state) => state.screens.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getAllScreenChapters = createSelector(
  (state) => state.screenChapters.dictionary,
  (_, screenId) => screenId,
  (dictionary, screenId) => {
    const chapterAttributes = Object.values(dictionary).filter(
      (chapter) => chapter.attributes.screen_id !== screenId
    );

    return chapterAttributes;
  }
);

export default screenSlice.reducer;
