/*
Replace
client with your resource Name :) Take care about different writings!
/!\ Client client Clients clients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const clientSlice = createSlice({
  name: "clients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listClients,
  show: showClient,
  create: createClient,
  update: updateClient,
} = clientSlice.actions;

export const actions = clientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const clients = (state) =>
  state.clients.idsList.map((id) => state.clients.dictionary[id]);
export const client = (state) =>
  state.clients.showId && state.clients.dictionary[state.clients.showId];
export const clientAttributes = (state) => client(state)?.attributes;
export const clientRelationships = (state) => client(state)?.relationships;
export const clientId = (state) => state.clients.showId;

export const getClient = createSelector(
  (state) => state.clients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getClientAttributes = createSelector(
  (state) => state.clients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getClientRelationships = createSelector(
  (state) => state.clients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getClients = createSelector(
  (state) => state.clients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default clientSlice.reducer;
