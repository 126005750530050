/**
 * Actions and ActionTypes definitions for the user professional_account managment.
 * ActionTypes are the constants used to specify a specific action.
 * Actions are generated with redux-actions' createAction method.
 * Each action involving a fetch request also defines a SUCCESS and FAIL action called after the fetch result
 */
import { createAction } from "redux-actions";

/** Login action-types */
const LOGIN_ACTION = "professional_account/LOGIN_ACTION";
const LOGIN_ACTION_SUCCESS = "professional_account/LOGIN_ACTION_SUCCESS";
const LOGIN_ACTION_FAIL = "professional_account/LOGIN_ACTION_FAIL";

/** Logout action-types */
const LOGOUT_ACTION = "professional_account/LOGOUT_ACTION";
const LOGOUT_ACTION_SUCCESS = "professional_account/LOGOUT_ACTION_SUCCESS";
const LOGOUT_ACTION_FAIL = "professional_account/LOGOUT_ACTION_FAIL";

/** Register action-types */
const REGISTER_ACTION = "professional_account/REGISTER_ACTION";
const REGISTER_ACTION_SUCCESS = "professional_account/REGISTER_ACTION_SUCCESS";
const REGISTER_ACTION_FAIL = "professional_account/REGISTER_ACTION_FAIL";

/** Social Login action-types */
const SOCIAL_LOGIN_ACTION = "professional_account/SOCIAL_LOGIN_ACTION";
const SOCIAL_LOGIN_ACTION_SUCCESS =
  "professional_account/SOCIAL_LOGIN_ACTION_SUCCESS";
const SOCIAL_LOGIN_ACTION_FAIL =
  "professional_account/SOCIAL_LOGIN_ACTION_FAIL";

/** Password Recovery action-types */
const PWD_RECOVERY_ACTION = "professional_account/PWD_RECOVERY_ACTION";
const PWD_RECOVERY_ACTION_SUCCESS =
  "professional_account/PWD_RECOVERY_ACTION_SUCCESS";
const PWD_RECOVERY_ACTION_FAIL =
  "professional_account/PWD_RECOVERY_ACTION_FAIL";

/** Password Reset action-types */
const PWD_RESET_ACTION = "professional_account/PWD_RESET_ACTION";
const PWD_RESET_ACTION_SUCCESS =
  "professional_account/PWD_RESET_ACTION_SUCCESS";
const PWD_RESET_ACTION_FAIL = "professional_account/PWD_RESET_ACTION_FAIL";

/** Update Profile action-types */
const UPDATE_PROFILE_ACTION = "professional_account/UPDATE_PROFILE_ACTION";
const UPDATE_PROFILE_ACTION_SUCCESS =
  "professional_account/UPDATE_PROFILE_ACTION_SUCCESS";
const UPDATE_PROFILE_ACTION_FAIL =
  "professional_account/UPDATE_PROFILE_ACTION_FAIL";

const CLEAR_ERRORS_ACTION = "professional_account/CLEAR_ERRORS_ACTION";

/**
 * login action
 */
const loginSuccess = createAction(LOGIN_ACTION_SUCCESS);
const loginFail = createAction(LOGIN_ACTION_FAIL);
const login = createAction(LOGIN_ACTION);

/**
 * logout action
 */
const logoutSuccess = createAction(LOGOUT_ACTION_SUCCESS);
const logoutFail = createAction(LOGOUT_ACTION_FAIL);
const logout = createAction(LOGOUT_ACTION);

/**
 * social login action
 */
const socialLoginSuccess = createAction(SOCIAL_LOGIN_ACTION_SUCCESS);
const socialLoginFail = createAction(SOCIAL_LOGIN_ACTION_FAIL);
const socialLogin = createAction(SOCIAL_LOGIN_ACTION);

/**
 * register action
 */
const registerSuccess = createAction(REGISTER_ACTION_SUCCESS);
const registerFail = createAction(REGISTER_ACTION_FAIL);
const register = createAction(REGISTER_ACTION);

/**
 * pwdRecovery action
 */
const pwdRecoverySuccess = createAction(PWD_RECOVERY_ACTION_SUCCESS);
const pwdRecoveryFail = createAction(PWD_RECOVERY_ACTION_FAIL);
const pwdRecovery = createAction(PWD_RECOVERY_ACTION);

/**
 * pwdReset action
 */
const pwdResetSuccess = createAction(PWD_RESET_ACTION_SUCCESS);
const pwdResetFail = createAction(PWD_RESET_ACTION_FAIL);
const pwdReset = createAction(PWD_RESET_ACTION);

/**
 * updateProfile action
 */
const updateProfileSuccess = createAction(UPDATE_PROFILE_ACTION_SUCCESS);
const updateProfileFail = createAction(UPDATE_PROFILE_ACTION_FAIL);
const updateProfile = createAction(UPDATE_PROFILE_ACTION);

const clearErrors = createAction(CLEAR_ERRORS_ACTION);

/** exports all actions as default */
const actions = {
  login: login,
  loginSuccess: loginSuccess,
  loginFail: loginFail,
  logout: logout,
  logoutSuccess: logoutSuccess,
  logoutFail: logoutFail,
  register: register,
  registerSuccess: registerSuccess,
  registerFail: registerFail,
  socialLogin: socialLogin,
  socialLoginSuccess: socialLoginSuccess,
  socialLoginFail: socialLoginFail,
  pwdRecovery: pwdRecovery,
  pwdRecoverySuccess: pwdRecoverySuccess,
  pwdRecoveryFail: pwdRecoveryFail,
  pwdReset: pwdReset,
  pwdResetSuccess: pwdResetSuccess,
  pwdResetFail: pwdResetFail,
  updateProfile: updateProfile,
  updateProfileSuccess: updateProfileSuccess,
  updateProfileFail: updateProfileFail,
  clearErrors: clearErrors,
};

export default actions;
