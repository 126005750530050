/**
 * The root reducer function. This function is used by the store to mutate its state. All reducers should subscirbe in this file in order to have effect
 */
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { i18nState } from "redux-i18n";
import servWork from "./servWork/reducers";
import notifications from "./notifications/reducers";
import account from "./account/reducers";
import ingredients from "./ingredients";
import recipes from "./recipes";
import posMedias from "./pos_media";
import recipeSteps from "./recipe_steps";
import ingredientsGroups from "./ingredients_groups";
import allergens from "./allergens";
import counters from "./counters";
import dishes from "./dishes";
import menuDishes from "./menu_dishes";
import timeslots from "./timeslots";
import healthyBenefits from "./healthy_benefits";
import articles from "./articles";
import producers from "./producers";
import clients from "./clients";
import nutrients from "./nutrients";
import locations from "./locations";
import techniques from "./techniques";
import techniqueSteps from "./technique_steps";
import ingredientNutrients from "./ingredient_nutrients";
import certificates from "./certificates";
import products from "./products";
import screens from "./screens";
import screenChapters from "./screen_chapters";
import courses from "./courses";
import dailyMenus from "./daily_menus";
import lessonChapterAnswers from "./lesson_chapter_answers";
import lessonChapters from "./lesson_chapters";
import lessons from "./lessons";
import professionalAccounts from "./professional_account";
import professionalUsers from "./professional_users";
import recipeIngredients from "./recipe_ingredients";
import recipeStepIngredients from "./recipe_step_ingredients";
import users from "./users";
import weeklyMenus from "./weekly_menus";
import feedbacks from "./feedbacks";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports all reducers through the combineReducers function */
const reducers = (history) =>
  combineReducers({
    router: connectRouter(history),
    i18nState,
    servWork,
    notifications,
    courses,
    dailyMenus,
    lessonChapterAnswers,
    lessonChapters,
    lessons,
    professionalAccounts,
    professionalUsers,
    recipeIngredients,
    recipeStepIngredients,
    users,
    posMedias,
    weeklyMenus,
    account,
    ingredients,
    recipes,
    recipeSteps,
    ingredientsGroups,
    healthyBenefits,
    allergens,
    counters,
    articles,
    nutrients,
    locations,
    techniques,
    techniqueSteps,
    screens,
    screenChapters,
    producers,
    clients,
    ingredientNutrients,
    certificates,
    products,
    dishes,
    menuDishes,
    timeslots,
    feedbacks,
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded reducers
  });

export default reducers;
