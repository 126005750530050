/**
 * The root saga function. This function is used to register all sagas watching the store
 */
import notificationsSaga from "./notifications/sagas";
import { all } from "redux-saga/effects";
import accountSaga from "./account/sagas";
import dictionarySaga from "./dictionarySagas";
import serviceWorkerSaga from "./servWork/sagas";
import ingredientsSagas from "./ingredients/sagas";
import recipesSagas from "./recipes/sagas";
import recipeStepsSagas from "./recipe_steps/sagas";
import allergensSagas from "./allergens/sagas";
import countersSagas from "./counters/sagas";
import posMediasSagas from "./pos_media/sagas";
import dishesSagas from "./dishes/sagas";
import menuDishesSagas from "./menu_dishes/sagas";
import healthyBenefitsSagas from "./healthy_benefits/sagas";
import timeslotsSagas from "./timeslots/sagas";
import articlesSagas from "./articles/sagas";
import producersSagas from "./producers/sagas";
import clientsSagas from "./clients/sagas";
import ingredientsGroupsSagas from "./ingredients_groups/sagas";
import nutrientsSagas from "./nutrients/sagas";
import locationsSagas from "./locations/sagas";
import techniquesSagas from "./techniques/sagas";
import techniquesStepsSagas from "./technique_steps/sagas";
import certificatesSagas from "./certificates/sagas";
import productsSagas from "./products/sagas";
import screensSagas from "./screens/sagas";
import screenChaptersSagas from "./screen_chapters/sagas";
import coursesSagas from "./courses/sagas";
import dailyMenusSagas from "./daily_menus/sagas";
import lessonChapterAnswersSagas from "./lesson_chapter_answers/sagas";
import lessonChapterSagas from "./lesson_chapters/sagas";
import lessonsSagas from "./lessons/sagas";
import professionalAccountSagas from "./professional_account/sagas";
import professionalUserSagas from "./professional_users/sagas";
import recipeIngredientsSagas from "./recipe_ingredients/sagas";
import recipeStepIngredientsSagas from "./recipe_step_ingredients/sagas";
import usersSagas from "./users/sagas";
import weeklyMenusSagas from "./weekly_menus/sagas";
import feedbacksSagas from "./feedbacks/sagas";

// DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
// new scaffolded imports

/** exports the rootSaga for the store */
export default function* rootSaga() {
  yield all([
    notificationsSaga(),
    dictionarySaga(),
    accountSaga(),
    serviceWorkerSaga(),
    ingredientsSagas(),
    recipesSagas(),
    recipeStepsSagas(),
    coursesSagas(),
    dailyMenusSagas(),
    posMediasSagas(),
    lessonChapterAnswersSagas(),
    lessonChapterSagas(),
    lessonsSagas(),
    professionalAccountSagas(),
    professionalUserSagas(),
    recipeIngredientsSagas(),
    recipeStepIngredientsSagas(),
    usersSagas(),
    weeklyMenusSagas(),
    allergensSagas(),
    countersSagas(),
    articlesSagas(),
    ingredientsGroupsSagas(),
    healthyBenefitsSagas(),
    producersSagas(),
    clientsSagas(),
    nutrientsSagas(),
    locationsSagas(),
    techniquesSagas(),
    techniquesStepsSagas(),
    certificatesSagas(),
    productsSagas(),
    screensSagas(),
    screenChaptersSagas(),
    dishesSagas(),
    menuDishesSagas(),
    timeslotsSagas(),
    feedbacksSagas(),
    // DON'T DELETE THE FOLLOWING COMMENT: NEEDED FOR UPDATING THIS FILE WITH THE SCAFFOLD SCRIPT
    // new scaffolded sagas
  ]);
}
