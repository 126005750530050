/*
Replace
lessonChapterAnswer with your resource Name :) Take care about different writings!
/!\ LessonChapterAnswer lessonChapterAnswer LessonChapterAnswers lessonChapterAnswers /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const lessonChapterAnswerSlice = createSlice({
  name: "lessonChapterAnswers",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listLessonChapterAnswers,
  show: showLessonChapterAnswer,
  create: createLessonChapterAnswer,
  update: updateLessonChapterAnswer,
} = lessonChapterAnswerSlice.actions;

export const actions = lessonChapterAnswerSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const lessonChapterAnswers = (state) =>
  state.lessonChapterAnswers.idsList.map(
    (id) => state.lessonChapterAnswers.dictionary[id]
  );
export const lessonChapterAnswer = (state) =>
  state.lessonChapterAnswers.showId &&
  state.lessonChapterAnswers.dictionary[state.lessonChapterAnswers.showId];
export const lessonChapterAnswerAttributes = (state) =>
  lessonChapterAnswer(state)?.attributes;
export const lessonChapterAnswerRelationships = (state) =>
  lessonChapterAnswer(state)?.relationships;
export const lessonChapterAnswerId = (state) =>
  state.lessonChapterAnswers.showId;

export const getLessonChapterAnswer = createSelector(
  (state) => state.lessonChapterAnswers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getLessonChapterAnswerAttributes = createSelector(
  (state) => state.lessonChapterAnswers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getLessonChapterAnswerRelationships = createSelector(
  (state) => state.lessonChapterAnswers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getLessonChapterAnswers = createSelector(
  (state) => state.lessonChapterAnswers.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default lessonChapterAnswerSlice.reducer;
