export const translations = {
  'de': {
    'Healthy Fact': 'HEALTHY FACT',
    'Producer In Focus': 'PRODUZENT IM FOKUS',
    'Product in Focus': 'PRODUKT IM FOKUS',
    'Allergens': 'Allergene',
    'Vegetarian': 'Vegetarisch',
    'conventional': 'konventionell',
    'MSC Fish': 'MSC Fisch',
    'All dishes are of organic quality, unless otherwise indicated.': 'Alle Speisen sind in Bio Qualität, wenn nicht anders gekennzeichnet.',
    'Fresh Fruits': 'Frisches Obst',
    'Natural Yogurt': 'Naturjoghurt',
    'No allergens in the dish.': 'Dieses Gericht enthält keine Allergene.',
    'Main Dish': 'Hauptgericht',
    'Dessert': 'Nachspeise',
    'Monday': 'Montag',
    'Tuesday': 'Dienstag',
    'Wednesday': 'Mittwoch',
    'Thursday': 'Donnerstag',
    'Friday': 'Freitag',
    'Lunch Menu': 'Speiseplan',
    'ForOFour.message': '',
    'ForOFour.button': '',
  },
  'options': {
    'plural_rule': 'n != 1',
    'plural_number': '2',
  },
  'en': {
    'Healthy Fact': 'Healthy Fact',
    'Producer In Focus': 'Producer In Focus',
    'Product in Focus': 'Product in Focus',
    'Allergens': 'Allergens',
    'Vegetarian': 'Vegetarian',
    'conventional': 'conventional',
    'MSC Fish': 'MSC Fish',
    'All dishes are of organic quality, unless otherwise indicated.': 'All dishes are of organic quality, unless otherwise indicated.',
    'Fresh Fruits': 'Fresh Fruits',
    'Natural Yogurt': 'Natural Yogurt',
    'No allergens in the dish.': 'No allergens in the dish.',
    'Main Dish': 'Main Dish',
    'Dessert': 'Dessert',
    'Monday': 'Monday',
    'Tuesday': 'Tuesday',
    'Wednesday': 'Wednesday',
    'Thursday': 'Thursday',
    'Friday': 'Friday',
    'Lunch Menu': 'Lunch Menu',
    'ForOFour.message': 'ForOFour.message',
    'ForOFour.button': 'ForOFour.button',
  },
  'it': {
    'Name': '',
    'Description': '',
    'Submit': 'Invia',
    'Items.header': 'Ciao Bakerplate',
    'Items.header.offline': 'Ciao offline Bakerplate',
    'YYYY-MM-DD': 'DD.MM.YYYY',
    'LoadingContent.message': '',
    'Email': 'Email',
    'Password': 'Password',
    'ForgotPassword': 'Dimenticato le credenziali? Clicca',
    'ForgotPassword.here': 'qui',
    'NewPassword': 'Nuova Password',
    'NewPasswordConfirmation': 'Conferma Nuova Password',
    'PasswordConfirmation': 'Conferma Password',
    'Number': '',
    'Text': '',
    'TextArea': '',
    'checkbox': '',
    'Switch': '',
    'Male': '',
    'Female': '',
    'RadioGroup': '',
    'select': '',
    'multi-select': '',
    'ForOFour.message': '',
    'ForOFour.button': '',
    'Home.header': 'Ciao Bakerplate',
    'Home.header.offline': 'Ciao offline Bakerplate',
  },
}
