/*
Replace
healthyBenefit with your resource Name :) Take care about different writings!
/!\ HealthyBenefit healthyBenefit HealthyBenefits healthyBenefits /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const healthyBenefitSlice = createSlice({
  name: "healthyBenefits",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listHealthyBenefits,
  show: showHealthyBenefit,
  create: createHealthyBenefit,
  update: updateHealthyBenefit,
} = healthyBenefitSlice.actions;

export const actions = healthyBenefitSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const healthyBenefits = (state) =>
  state.healthyBenefits.idsList.map(
    (id) => state.healthyBenefits.dictionary[id]
  );
export const healthyBenefit = (state) =>
  state.healthyBenefits.showId &&
  state.healthyBenefits.dictionary[state.healthyBenefits.showId];
export const healthyBenefitAttributes = (state) =>
  healthyBenefit(state)?.attributes;
export const healthyBenefitRelationships = (state) =>
  healthyBenefit(state)?.relationships;
export const healthyBenefitId = (state) => state.healthyBenefits.showId;

export const getHealthyBenefit = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getHealthyBenefitAttributes = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getHealthyBenefitRelationships = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getHealthyBenefits = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getHealthyBenefitIdsForDate = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter(
        (_healthyBenefit) =>
          _healthyBenefit[1].attributes.date_of_serving === day
      )
      .map((_healthyBenefit) => _healthyBenefit[0]);
  }
);

export const getHealthyBenefitIdsForDateAndTimeslot = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (dictionary, { singleDayId, timeslotId }) => {
    return Object.entries(dictionary)
      .filter(
        (_healthyBenefit) =>
          _healthyBenefit[1].attributes.date_of_serving === singleDayId &&
          _healthyBenefit[1].relationships.timeslot.data.id === timeslotId
      )
      .map((_healthyBenefit) => _healthyBenefit[0]);
  }
);

export const getHealthyBenefitTitlesForRecipes = createSelector(
  (state) => state.healthyBenefits.dictionary,
  (_, recipeIds) => recipeIds,
  (dictionary, recipeIds) => {
    const recipeIdSet = new Set(recipeIds.map(String));
    const filteredDictionary = Object.entries(dictionary)
      .filter(([_, value]) => {
        const { recipes } = value.relationships;
        const ids = recipes.data.map(({ id }) => id);
        return ids.some((id) => recipeIdSet.has(id));
      })
      .map(([_, value]) => {
        return { id: value?.id, title: value?.attributes?.title };
      });

    return filteredDictionary;
  }
);

export default healthyBenefitSlice.reducer;
