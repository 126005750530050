/**
 * The Root container
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import OfflineBar from "../components/OfflineBar";
import Loadable from "react-loadable";
import FourOFour from "./FourOFour";

/**
 * Loadable import for ScreenAutoLoader container
 */
const ScreenAutoLoader = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ScreenAutoLoader" */ "./ScreenAutoLoader"),
  loading: () => null,
  modules: ["ScreenAutoLoader"],
});

const Sizing = Loadable({
  loader: () => import(/* webpackChunkName: "Sizing" */ "./Sizing"),
  loading: () => null,
  modules: ["Sizing"],
});

/**
 * The Root container used as entry point for the react-router.
 * All routes are lazy-loaded with Loadable.
 */
class Root extends Component {
  render() {
    return (
      <StickyContainer id="Root">
        <OfflineBar />
        <div id="content">
          <Switch>
            <Route path={"/"} exact component={ScreenAutoLoader} />
            <Route path={"/sizing"} exact component={Sizing} />
            <Route component={FourOFour} />
          </Switch>
        </div>
      </StickyContainer>
    );
  }
}

/**
 * Selects props from the store and passes them to the component
 * @param {Object} state the store
 */
const mapStateToProps = (state) => ({});
/**
 * Binds dispatch method to the actions passed as props to the component
 */
const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

/** export the redux connected component and add a frontload call */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Root));
