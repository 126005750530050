/*
Replace
recipeStepIngredient with your resource Name :) Take care about different writings!
/!\ RecipeStepIngredient recipeStepIngredient RecipeStepIngredients recipeStepIngredients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const recipeStepIngredientSlice = createSlice({
  name: "recipeStepIngredients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listRecipeStepIngredients,
  show: showRecipeStepIngredient,
  create: createRecipeStepIngredient,
  update: updateRecipeStepIngredient,
} = recipeStepIngredientSlice.actions;

export const actions = recipeStepIngredientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const recipeStepIngredients = (state) =>
  state.recipeStepIngredients.idsList.map(
    (id) => state.recipeStepIngredients.dictionary[id]
  );
export const recipeStepIngredient = (state) =>
  state.recipeStepIngredients.showId &&
  state.recipeStepIngredients.dictionary[state.recipeStepIngredients.showId];
export const recipeStepIngredientAttributes = (state) =>
  recipeStepIngredient(state)?.attributes;
export const recipeStepIngredientRelationships = (state) =>
  recipeStepIngredient(state)?.relationships;
export const recipeStepIngredientId = (state) =>
  state.recipeStepIngredients.showId;

export const getRecipeStepIngredient = createSelector(
  (state) => state.recipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getRecipeStepIngredientAttributes = createSelector(
  (state) => state.recipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getRecipeStepIngredientRelationships = createSelector(
  (state) => state.recipeStepIngredients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getRecipeStepIngredients = createSelector(
  (state) => state.recipeStepIngredients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default recipeStepIngredientSlice.reducer;
