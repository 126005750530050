/*
Replace
menuDish with your resource Name :) Take care about different writings!
/!\ MenuDish menuDish MenuDishes menuDishes /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const menuDishSlice = createSlice({
  name: "menuDishes",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listMenuDishes,
  show: showMenuDish,
  create: createMenuDish,
  update: updateMenuDish,
} = menuDishSlice.actions;

export const actions = menuDishSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const menuDishes = (state) =>
  state.menuDishes.idsList.map((id) => state.menuDishes.dictionary[id]);
export const menuDish = (state) =>
  state.menuDishes.showId &&
  state.menuDishes.dictionary[state.menuDishes.showId];
export const menuDishAttributes = (state) => menuDish(state)?.attributes;
export const menuDishRelationships = (state) => menuDish(state)?.relationships;
export const menuDishId = (state) => state.menuDishes.showId;

export const getMenuDish = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getMenuDishAttributes = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getMenuDishRelationships = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getMenuDishes = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export const getMenuDishIdsForDate = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, day) => day,
  (dictionary, day) => {
    return Object.entries(dictionary)
      .filter((_menuDish) => _menuDish[1].attributes.date_of_serving === day)
      .map((_menuDish) => _menuDish[0]);
  }
);

export const getMenuDishIdsForDateAndTimeslot = createSelector(
  (state) => state.menuDishes.dictionary,
  (_, singleDayId) => singleDayId,
  (_, timeslotId) => timeslotId,
  (dictionary, { singleDayId, timeslotId }) => {
    return Object.entries(dictionary)
      .filter(
        (_menuDish) =>
          _menuDish[1].attributes.date_of_serving === singleDayId &&
          _menuDish[1].relationships.timeslot.data.id === timeslotId
      )
      .map((_menuDish) => _menuDish[0]);
  }
);

export default menuDishSlice.reducer;
