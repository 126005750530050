/*
Replace
article with your resource Name :) Take care about different writings!
/!\ Article article Articles articles /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const articleSlice = createSlice({
  name: "articles",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listArticles,
  show: showArticle,
  create: createArticle,
  update: updateArticle,
} = articleSlice.actions;

export const actions = articleSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const articles = (state) =>
  state.articles.idsList.map((id) => state.articles.dictionary[id]);
export const article = (state) =>
  state.articles.showId && state.articles.dictionary[state.articles.showId];
export const articleAttributes = (state) => article(state)?.attributes;
export const articleRelationships = (state) => article(state)?.relationships;
export const articleId = (state) => state.articles.showId;

export const getArticle = createSelector(
  (state) => state.articles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getArticleAttributes = createSelector(
  (state) => state.articles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getArticleRelationships = createSelector(
  (state) => state.articles.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getArticles = createSelector(
  (state) => state.articles.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default articleSlice.reducer;
