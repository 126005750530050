/*
Replace
nutrient with your resource Name :) Take care about different writings!
/!\ Nutrient nutrient Nutrients nutrients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const nutrientSlice = createSlice({
  name: "nutrients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listNutrients,
  show: showNutrient,
  create: createNutrient,
  update: updateNutrient,
} = nutrientSlice.actions;

export const actions = nutrientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const nutrients = (state) =>
  state.nutrients.idsList.map((id) => state.nutrients.dictionary[id]);
export const nutrient = (state) =>
  state.nutrients.showId && state.nutrients.dictionary[state.nutrients.showId];
export const nutrientAttributes = (state) => nutrient(state)?.attributes;
export const nutrientRelationships = (state) => nutrient(state)?.relationships;
export const nutrientId = (state) => state.nutrients.showId;

export const getNutrient = createSelector(
  (state) => state.nutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getNutrientAttributes = createSelector(
  (state) => state.nutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getNutrientRelationships = createSelector(
  (state) => state.nutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getNutrients = createSelector(
  (state) => state.nutrients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default nutrientSlice.reducer;
