/*
Replace
product with your resource Name :) Take care about different writings!
/!\ Product product Products products /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const productSlice = createSlice({
  name: "products",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listProducts,
  show: showProduct,
  create: createProduct,
  update: updateProduct,
} = productSlice.actions;

export const actions = productSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const products = (state) =>
  state.products.idsList.map((id) => state.products.dictionary[id]);
export const product = (state) =>
  state.products.showId && state.products.dictionary[state.products.showId];
export const productAttributes = (state) => product(state)?.attributes;
export const productRelationships = (state) => product(state)?.relationships;
export const productId = (state) => state.products.showId;

export const getProduct = createSelector(
  (state) => state.products.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getProductAttributes = createSelector(
  (state) => state.products.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getProductRelationships = createSelector(
  (state) => state.products.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getProducts = createSelector(
  (state) => state.products.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default productSlice.reducer;
