/*
Replace
professionalUser with your resource Name :) Take care about different writings!
/!\ ProfessionalUser professionalUser ProfessionalUsers professionalUsers /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const professionalUserSlice = createSlice({
  name: "professionalUsers",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listProfessionalUsers,
  show: showProfessionalUser,
  create: createProfessionalUser,
  update: updateProfessionalUser,
} = professionalUserSlice.actions;

export const actions = professionalUserSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const professionalUsers = (state) =>
  state.professionalUsers.idsList.map(
    (id) => state.professionalUsers.dictionary[id]
  );
export const professionalUser = (state) =>
  state.professionalUsers.showId &&
  state.professionalUsers.dictionary[state.professionalUsers.showId];
export const professionalUserAttributes = (state) =>
  professionalUser(state)?.attributes;
export const professionalUserRelationships = (state) =>
  professionalUser(state)?.relationships;
export const professionalUserId = (state) => state.professionalUsers.showId;

export const getProfessionalUser = createSelector(
  (state) => state.professionalUsers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getProfessionalUserAttributes = createSelector(
  (state) => state.professionalUsers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getProfessionalUserRelationships = createSelector(
  (state) => state.professionalUsers.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getProfessionalUsers = createSelector(
  (state) => state.professionalUsers.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default professionalUserSlice.reducer;
