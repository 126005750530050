/**
 * The 404 component
 */
import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from 'reactstrap';
import { Link } from "react-router-dom";

const FourOFour = (props, context) => {
  
  return (
    <div>
      <section className='fourofour__hero' style={ {backgroundImage: 'url()'} }>
        <Container>
        <Row>
          <Col xs={12}>
            <h1>{context.t("ForOFour.message")}</h1>
          </Col>
          <Col className="fourofour__container__cta" xs={ {size: 6, offset: 3} } md={ {size: 4, offset: 4} }>
              <Link to={`/`} className="btn btn-primary btn-block">
                {context.t("ForOFour.button")}
              </Link>
          </Col>
          </Row>
        </Container>          
      </section>
    </div>
  );
}
/**  define proptype for the 'translation' function  */
FourOFour.contextTypes = {
  t: PropTypes.func
}
export default FourOFour;
