/** ----- buildHeaders.js ----- 
*  File: buildHeaders.js
*
*  Purpose: providing helper functions to set headers for api requests
*  
*-----------------------------*/

function buildHeaders(user) {
  if (user && user.tokens && user.tokens['access-token']) {
    return {
      'Content-Type': 'application/json',
      'access-token': user.tokens['access-token'],
      'client': user.tokens.client,
      'uid': user.tokens.uid,
      'token-type': "Bearer"
    }
  }
  return {
    'Content-Type': 'application/json'
  }
}

export default buildHeaders;
