/*
Replace
posMedia with your resource Name :) Take care about different writings!
/!\ PosMedia posMedia PosMedias posMedias /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const posMediaSlice = createSlice({
  name: "posMedias",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listPosMedias,
  show: showPosMedia,
  create: createPosMedia,
  update: updatePosMedia,
} = posMediaSlice.actions;

export const actions = posMediaSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const posMedias = (state) =>
  state.posMedias.idsList.map((id) => state.posMedias.dictionary[id]);
export const posMedia = (state) =>
  state.posMedias.showId && state.posMedias.dictionary[state.posMedias.showId];
export const posMediaAttributes = (state) => posMedia(state)?.attributes;
export const posMediaRelationships = (state) => posMedia(state)?.relationships;
export const posMediaId = (state) => state.posMedias.showId;

export const getPosMedia = createSelector(
  (state) => state.posMedias.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getPosMediaAttributes = createSelector(
  (state) => state.posMedias.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getPosMediaRelationships = createSelector(
  (state) => state.posMedias.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getPosMedias = createSelector(
  (state) => state.posMedias.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default posMediaSlice.reducer;
