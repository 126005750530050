/*
Replace
feedback with your resource Name :) Take care about different writings!
/!\ Feedback feedback Feedbacks feedbacks /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateFail,
  defaultAddToDictionary,
  defaultRemove,
  defaultRemoveSuccess,
  defaultRemoveFail,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState: {
    ...defaultInitialState,
  },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: (state, action) => {
      state.creating = false;
      state.created = true;
      state.show = action.payload;
      state.showId = action.payload.id;
      state.dictionary[action.payload.id] = action.payload;
      state.createError = null;
    },
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
    remove: defaultRemove,
    removeSuccess: defaultRemoveSuccess,
    removeFail: defaultRemoveFail,
    clearFeedback: (state) => {
      state.created = false;
      state.updated = false;
      state.showId = null;
      state.show = null;
    },
  },
});

export const {
  list: listFeedbacks,
  show: showFeedback,
  create: createFeedback,
  update: updateFeedback,
  remove: removeFeedback,
  clearFeedback,
} = feedbackSlice.actions;

export const actions = feedbackSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const feedbacks = (state) =>
  state.feedbacks.idsList.map((id) => state.feedbacks.dictionary[id]);
export const feedback = (state) =>
  state.feedbacks.showId && state.feedbacks.dictionary[state.feedbacks.showId];
export const feedbackAttributes = (state) => feedback(state)?.attributes;
export const feedbackRelationships = (state) => feedback(state)?.relationships;
export const feedbackId = (state) => state.feedbacks.showId;

export const getFeedback = createSelector(
  (state) => state.feedbacks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getFeedbackAttributes = createSelector(
  (state) => state.feedbacks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getFeedbackRelationships = createSelector(
  (state) => state.feedbacks.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getFeedbacks = createSelector(
  (state) => state.feedbacks.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default feedbackSlice.reducer;
