/*
Replace
techniqueStep with your resource Name :) Take care about different writings!
/!\ TechniqueStep techniqueStep TechniqueSteps techniqueSteps /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const techniqueStepSlice = createSlice({
  name: "techniqueSteps",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listTechniqueSteps,
  show: showTechniqueStep,
  create: createTechniqueStep,
  update: updateTechniqueStep,
} = techniqueStepSlice.actions;

export const actions = techniqueStepSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const techniqueSteps = (state) =>
  state.techniqueSteps.idsList.map((id) => state.techniqueSteps.dictionary[id]);
export const techniqueStep = (state) =>
  state.techniqueSteps.showId &&
  state.techniqueSteps.dictionary[state.techniqueSteps.showId];
export const techniqueStepAttributes = (state) =>
  techniqueStep(state)?.attributes;
export const techniqueStepRelationships = (state) =>
  techniqueStep(state)?.relationships;
export const techniqueStepId = (state) => state.techniqueSteps.showId;

export const getTechniqueStep = createSelector(
  (state) => state.techniqueSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getTechniqueStepAttributes = createSelector(
  (state) => state.techniqueSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getTechniqueStepRelationships = createSelector(
  (state) => state.techniqueSteps.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getTechniqueSteps = createSelector(
  (state) => state.techniqueSteps.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default techniqueStepSlice.reducer;
