/*
Replace
ingredientsGroup with your resource Name :) Take care about different writings!
/!\ IngredientsGroup ingredientsGroup IngredientsGroups ingredientsGroups /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const ingredientsGroupSlice = createSlice({
  name: "ingredientsGroups",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listIngredientsGroups,
  show: showIngredientsGroup,
  create: createIngredientsGroup,
  update: updateIngredientsGroup,
} = ingredientsGroupSlice.actions;

export const actions = ingredientsGroupSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const ingredientsGroups = (state) =>
  state.ingredientsGroups.idsList.map(
    (id) => state.ingredientsGroups.dictionary[id]
  );
export const ingredientsGroup = (state) =>
  state.ingredientsGroups.showId &&
  state.ingredientsGroups.dictionary[state.ingredientsGroups.showId];
export const ingredientsGroupAttributes = (state) =>
  ingredientsGroup(state)?.attributes;
export const ingredientsGroupRelationships = (state) =>
  ingredientsGroup(state)?.relationships;
export const ingredientsGroupId = (state) => state.ingredientsGroups.showId;

export const getIngredientsGroup = createSelector(
  (state) => state.ingredientsGroups.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getIngredientsGroupAttributes = createSelector(
  (state) => state.ingredientsGroups.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getIngredientsGroupRelationships = createSelector(
  (state) => state.ingredientsGroups.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getIngredientsGroups = createSelector(
  (state) => state.ingredientsGroups.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default ingredientsGroupSlice.reducer;
