/*
Replace
weeklyMenu with your resource Name :) Take care about different writings!
/!\ WeeklyMenu weeklyMenu WeeklyMenus weeklyMenus /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const weeklyMenuSlice = createSlice({
  name: "weeklyMenus",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listWeeklyMenus,
  show: showWeeklyMenu,
  create: createWeeklyMenu,
  update: updateWeeklyMenu,
} = weeklyMenuSlice.actions;

export const actions = weeklyMenuSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const weeklyMenus = (state) =>
  state.weeklyMenus.idsList.map((id) => state.weeklyMenus.dictionary[id]);
export const weeklyMenu = (state) =>
  state.weeklyMenus.showId &&
  state.weeklyMenus.dictionary[state.weeklyMenus.showId];
export const weeklyMenuAttributes = (state) => weeklyMenu(state)?.attributes;
export const weeklyMenuRelationships = (state) =>
  weeklyMenu(state)?.relationships;
export const weeklyMenuId = (state) => state.weeklyMenus.showId;

export const getWeeklyMenu = createSelector(
  (state) => state.weeklyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getWeeklyMenuAttributes = createSelector(
  (state) => state.weeklyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getWeeklyMenuRelationships = createSelector(
  (state) => state.weeklyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getWeeklyMenus = createSelector(
  (state) => state.weeklyMenus.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default weeklyMenuSlice.reducer;
