/*
Replace
dailyMenu with your resource Name :) Take care about different writings!
/!\ DailyMenu dailyMenu DailyMenus dailyMenus /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const dailyMenuSlice = createSlice({
  name: "dailyMenus",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listDailyMenus,
  show: showDailyMenu,
  create: createDailyMenu,
  update: updateDailyMenu,
} = dailyMenuSlice.actions;

export const actions = dailyMenuSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const dailyMenus = (state) =>
  state.dailyMenus.idsList.map((id) => state.dailyMenus.dictionary[id]);
export const dailyMenu = (state) =>
  state.dailyMenus.showId &&
  state.dailyMenus.dictionary[state.dailyMenus.showId];
export const dailyMenuAttributes = (state) => dailyMenu(state)?.attributes;
export const dailyMenuRelationships = (state) =>
  dailyMenu(state)?.relationships;
export const dailyMenuId = (state) => state.dailyMenus.showId;

export const getDailyMenu = createSelector(
  (state) => state.dailyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getDailyMenuAttributes = createSelector(
  (state) => state.dailyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getDailyMenuRelationships = createSelector(
  (state) => state.dailyMenus.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getDailyMenus = createSelector(
  (state) => state.dailyMenus.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default dailyMenuSlice.reducer;
