/*
Replace
weeklyMenu with your resource Name :) Take care about different writings!
/!\ WeeklyMenu weeklyMenu WeeklyMenus weeklyMenus /!\
Keep an eye on pluralized namings in here :)

*/
/**
 *  Sagas for the WeeklyMenus resource managment using Swagger client
 *
 * Each saga watcher intercepts a trigger action, does the asyncrhonous work in the respective worker saga and dispatches a success or a failure action.
 * Fetch calls are made via the swagger tags interface requests
 *
 */

import { call, put, takeEvery, select } from "redux-saga/effects";
import buildHeaders from "../../utils/buildHeaders";
import { actions } from "./index";
import { storeIncluded } from "../dictionarySagas";
import apiClient, { applyHeaders } from "../../utils/apiSwaggerRequest";
import DataLayerHelper from "../../utils/dataLayer";

/** Worker Sagas */

/** List Saga
 *  @description: connects to the getWeeklyMenu operation
 */
export function* list(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);

  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.WeeklyMenus.listWeeklyMenus,
      {},
      { requestInterceptor: applyHeaders(headers) }
    );
    if (payload.obj.included)
      yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.listSuccess, payload: payload });
  } catch (e) {
    yield put({ type: actions.listFail, payload: e });
    DataLayerHelper.addErrorEvent(
      "Load WeeklyMenu List",
      e.error || e.message || "WeeklyMenu Error"
    );
  }
}

/** Show Saga
 *  @description: connects to the showWeeklyMenu operation
 *  @param {number} action.payload the WeeklyMenu id
 */
export function* show(action) {
  const weeklyMenuId = action.payload;
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    const payload = yield call(
      SwaggerClient.apis.WeeklyMenus.showWeeklyMenu,
      { id: weeklyMenuId },
      { requestInterceptor: applyHeaders(headers) }
    );
    yield* storeIncluded({ payload: payload.obj.included });
    yield put({ type: actions.showSuccess, payload: payload.obj });
  } catch (e) {
    yield put({ type: actions.showFail, payload: e });
  }
}

export function* create(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.WeeklyMenus.createWeeklyMenu,
      {},
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.createSuccess, payload: payload.body });

    yield* storeIncluded({ payload: payload.obj.included });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent(
      "Create WeeklyMenu",
      `data to log, maybe id?`
    );
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}
export function* update(action) {
  const currentUser = yield select((state) => state.account.currentUser);
  let headers = buildHeaders(currentUser);
  let values = action.payload;
  try {
    const SwaggerClient = yield call(apiClient);
    let payload = yield call(
      SwaggerClient.apis.WeeklyMenus.updateWeeklyMenu,
      {
        id: values.id,
        weeklyMenu: values,
      },
      { requestInterceptor: applyHeaders(headers) }
    );

    yield put({ type: actions.createSuccess, payload: payload.body });

    yield* storeIncluded({ payload: payload.obj.included });

    if (action.payload.callback) {
      action.payload.callback();
    }

    DataLayerHelper.addSuccessEvent("Updated WeeklyMenu", `${values.id}`);
  } catch (e) {
    yield put({ type: actions.createFail, payload: e });
  }
}

/**
 * Saga Watchers
 * The exported list of sagas registered. When one of the action types is dispatched
 * the related worker saga is invoked.
 * Each saga is executed in a different thread
 */
function* transactionsSaga() {
  yield takeEvery(actions.list, list);
  yield takeEvery(actions.show, show);
  yield takeEvery(actions.create, create);
  yield takeEvery(actions.update, update);
}
export default transactionsSaga;
