/*
Replace
ingredientNutrient with your resource Name :) Take care about different writings!
/!\ IngredientNutrient ingredientNutrient IngredientNutrients ingredientNutrients /!\
Keep an eye on pluralized namings in here :)

*/

import { createSlice, createSelector } from "@reduxjs/toolkit";
import {
  defaultList,
  defaultListFail,
  defaultListSuccess,
  defaultShow,
  defaultShowFail,
  defaultShowSuccess,
  defaultUpdate,
  defaultUpdateSuccess,
  defaultUpdateFail,
  defaultCreate,
  defaultCreateSuccess,
  defaultCreateFail,
  defaultAddToDictionary,
} from "../defaultReducers";
import { defaultInitialState } from "../defaultInitialState";

export const ingredientNutrientSlice = createSlice({
  name: "ingredientNutrients",
  initialState: { ...defaultInitialState },
  reducers: {
    list: defaultList,
    listSuccess: defaultListSuccess,
    listFail: defaultListFail,
    show: defaultShow,
    showSuccess: defaultShowSuccess,
    showFail: defaultShowFail,
    create: defaultCreate,
    createSuccess: defaultCreateSuccess,
    createFail: defaultCreateFail,
    update: defaultUpdate,
    updateSuccess: defaultUpdateSuccess,
    updateFail: defaultUpdateFail,
    addToDictionary: defaultAddToDictionary,
  },
});

export const {
  list: listIngredientNutrients,
  show: showIngredientNutrient,
  create: createIngredientNutrient,
  update: updateIngredientNutrient,
} = ingredientNutrientSlice.actions;

export const actions = ingredientNutrientSlice.actions;
// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const ingredientNutrients = (state) =>
  state.ingredientNutrients.idsList.map(
    (id) => state.ingredientNutrients.dictionary[id]
  );
export const ingredientNutrient = (state) =>
  state.ingredientNutrients.showId &&
  state.ingredientNutrients.dictionary[state.ingredientNutrients.showId];
export const ingredientNutrientAttributes = (state) =>
  ingredientNutrient(state)?.attributes;
export const ingredientNutrientRelationships = (state) =>
  ingredientNutrient(state)?.relationships;
export const ingredientNutrientId = (state) => state.ingredientNutrients.showId;

export const getIngredientNutrient = createSelector(
  (state) => state.ingredientNutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id];
  }
);

export const getIngredientNutrientAttributes = createSelector(
  (state) => state.ingredientNutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.attributes;
  }
);

export const getIngredientNutrientRelationships = createSelector(
  (state) => state.ingredientNutrients.dictionary,
  (_, id) => id,
  (dictionary, id) => {
    return id && dictionary && dictionary[id]?.relationships;
  }
);

export const getIngredientNutrients = createSelector(
  (state) => state.ingredientNutrients.dictionary,
  (_, ids) => ids,
  (dictionary, ids) => {
    return ids && dictionary && ids.map((id) => dictionary[id]);
  }
);

export default ingredientNutrientSlice.reducer;
